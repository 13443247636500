const MATOMO_CONFIG_JSON_ID = 'rp-matomo-config';
const MATOMO_DIMENSION_DEVELOPER_ID = 1;
const MATOMO_DIMENSION_DEVELOPER_COUNTRY_CODE = 2;

class Matomo {
  constructor(config) {
    this.config = config;

    this.configure();
    this.load();
    this.trackUrlChanges();
  }

  configure() {
    // Matomo commands array, as per documentation
    window._paq = window._paq || [];

    if (this.config['developerId']) {
      window._paq.push(['setCustomDimension', MATOMO_DIMENSION_DEVELOPER_ID, this.config['developerId']]);
    }

    if (this.config['developerCountryCode']) {
      window._paq.push([
        'setCustomDimension', MATOMO_DIMENSION_DEVELOPER_COUNTRY_CODE, this.config['developerCountryCode']
      ]);
    }

    if (this.config['userId']) {
      window._paq.push(['setUserId', this.config['userId']]);
    }

    window._paq.push(['enableLinkTracking']);
    window._paq.push(['setTrackerUrl', this.config['rootUrl'] + '/matomo.php']);
    window._paq.push(['setSiteId', this.config['siteId']]);

    // track initial URL as page view
    this.trackUrl();
  }

  load() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = this.config['rootUrl'] + '/matomo.js';
    script.async = true;

    document.currentScript.after(script);
  }

  /**
   * Start monitoring URL changes and tracking them as page views.
   * This allows to track tab changes, which typically change hash part of URL.
   */
  trackUrlChanges() {
    // listen to events that are called for some URL changes
    window.addEventListener('hashchange', () => this.trackUrl());
    window.addEventListener('popstate', () => this.trackUrl());

    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    // monkey patch to monitor changes, for which events above might not be triggered
    window.history.pushState = (state, unused, url, ...rest) => {
      originalPushState.apply(window.history, [state, unused, url, ...rest]);

      // wait for the next tick when URL change is done
      setTimeout(() => this.trackUrl());
    };

    // monkey patch to monitor changes, for which events above might not be triggered
    window.history.replaceState = (state, unused, url, ...rest) => {
      originalReplaceState.apply(window.history, [state, unused, url, ...rest]);

      // wait for the next tick when URL change is done
      setTimeout(() => this.trackUrl());
    };
  }

  /**
   * Track current URL as page view.
   */
  trackUrl() {
    const url = this.getCurrentUrl();

    // avoid tracking the same URL multiple times in a row,
    // due to this method called by different triggers for a single URL change
    if (url !== this.lastTrackedUrl) {
      this.lastTrackedUrl = url;

      window._paq.push(['setCustomUrl', url]);
      window._paq.push(['trackPageView']);
    }
  }

  /**
   * Get current URL with numeric IDs in the path replaced by $id.
   * Replacing numeric IDs with $id allows to aggregate visits to the same page.
   */
  getCurrentUrl() {
    const url = new URL(window.location.href);
    url.pathname = url.pathname.replaceAll(/(\/)([0-9]+)(\/|$)/g, '$1$$id$3');
    return url.toString();
  }
}

/**
 * Load config from <script> tag with JSON inside.
 * See MatomoTrackingCode on backend for details.
 */
function getConfig() {
  const config = document.getElementById(MATOMO_CONFIG_JSON_ID);
  return config ? JSON.parse(config.textContent) : null;
}

const config = getConfig();
if (config) {
  new Matomo(config);
}
